import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Timeline = styled.div`
  ${tw`flex justify-center sm:flex-row w-1/4 p-4 relative border-l mx-auto border-indigo-400`};

  &:last-child {
    ${tw`pb-0`};
  }
`;

export const Details = styled.div`
  ${tw`mx-auto self-center`};
`;

export const Title = styled.div`
  ${tw`font-semibold text-lg text-green-700`};
`;

export const Link = styled.a`
  ${tw`text-green-700 hover:text-green-500`};
`;

export const Subtitle = styled.div`
  ${tw`text-xs text-blue-400`};
`;

export const Point = styled.span`
  ${tw`w-3 h-3 border border-indigo-400 bg-blue-200 rounded-full absolute`};
  left: -6px;
  top: 20px;
`;
