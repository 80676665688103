import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const Timeline = ({title, subtitle, website}) => (
  <Styled.Timeline>
    <Styled.Point />
    <Styled.Details>
      <Styled.Title>
        <Styled.Link href={website} target="_blank">
          {title}
        </Styled.Link>
      </Styled.Title>
      <Styled.Subtitle>{subtitle}</Styled.Subtitle>
    </Styled.Details>
  </Styled.Timeline>
);

Timeline.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  website: PropTypes.string
};

export default Timeline;
